<template>
  <div class="table-container">
    <table class="table is-hoverable is-striped is-fullwidth">
      <tbody>
        <tr>
          <th>Spänning / Fas</th>
          <td>400V / 3-Fas</td>
        </tr>
        <tr>
          <th>Säkring</th>
          <td>3x16A</td>
        </tr>
        <tr>
          <th>Elpatron</th>
          <td>Inbyggd 3kW</td>
        </tr>
        <tr>
          <th>Luft 7°C (Vatten 35°C)</th>
          <td>Tillförd effekt: 2,8kW
          <br>Avgiven effekt: 12,4kW
          <br>COP: 4,4</td>
        </tr>
        <tr>
          <th>Luft 7°C (Vatten 55°C)</th>
          <td>Tillförd effekt: 4,0kW
          <br>Avgiven effekt: 11,7kW
          <br>COP: 2,9</td>
        </tr>
        <tr>
          <th>Luft -7°C (Vatten 35°C)</th>
          <td>Tillförd effekt: 3,5kW
          <br>Avgiven effekt: 10,1kW
          <br>COP: 2,9</td>
        </tr>
        <tr>
          <th>Luft -7°C (Vatten 55°C)</th>
          <td>Tillförd effekt: 4,3kW
          <br>Avgiven effekt: 10,0kW
          <br>COP: 2,3</td>
        </tr>
        <tr>
          <th>Ljudeffekt</th>
          <td>63dBA</td>
        </tr>
        <tr>
          <th>Kompressor</th>
          <td>Mitsubishi Inverter</td>
        </tr>
        <tr>
          <th>Kondensor</th>
          <td>SWEP Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Vatttenflödesvolym</th>
          <td>
            1,9m
            <sup>3</sup>/h
          </td>
        </tr>
        <tr>
          <th>Högsta Kondenseringstemperatur</th>
          <td>55°C</td>
        </tr>
        <tr>
          <th>Anslutningsgänga</th>
          <td>G25</td>
        </tr>
        <tr>
          <th>Anslutningsrör (Vatten)</th>
          <td>Minst 22mm koppar / 25mm PEX</td>
        </tr>
        <tr>
          <th>Cirkulationspump</th>
          <td>Inbyggd 8/25 A-klass</td>
        </tr>
        <tr>
          <th>Köldmedium</th>
          <td>R32</td>
        </tr>
        <tr>
          <th>Köldmediummängd</th>
          <td>2100g</td>
        </tr>
        <tr>
          <th>Vattenfilter</th>
          <td>Extern filterball med magnet (Medföljer)</td>
        </tr>
        <tr>
          <th>Vikt</th>
          <td>116kg</td>
        </tr>
        <tr>
          <th>Mått (LxBxH)</th>
          <td>1120x450x1230mm</td>
        </tr>
        <tr>
          <th>Garanti</th>
          <td>2 år med möjlighet till förlängning</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'IRLT1230TekniskSpec'
}
</script>
