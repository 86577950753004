<template>
  <div class="columns is-multiline is-mobile">
    <div class="column is-half">
      <figure class="image">
        <img style="max-height: 340px;" src="/assets/energyLabels/lt_1230.svg" />
      </figure>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IRLT1230Utförande'
}
</script>
