<template>
  <div class="table-container">
    <table class="table is-hoverable is-striped is-fullwidth">
      <tbody>
        <tr>
          <th>Spänning / Fas</th>
          <td>230V / 1-fas</td>
        </tr>
        <tr>
          <th>Minsta Säkring</th>
          <td>1x16A</td>
        </tr>
        <tr>
          <th>Avgiven Effekt (Luft 7°C / Vatten 30°C)</th>
          <td>9kW (COP 4,1)</td>
        </tr>
        <tr>
          <th>Avgiven Effekt (Luft -7°C / Vatten 50°C)</th>
          <td>6,9kW (COP 2,9)</td>
        </tr>
        <tr>
          <th>Avgiven Effekt (Luft -10°C / Vatten 55°C)</th>
          <td>6,1kW (COP 2,8)</td>
        </tr>
        <tr>
          <th>Ljudnivå</th>
          <td>49dBA</td>
        </tr>
        <tr>
          <th>Kompressor</th>
          <td>Mitsubishi Inverter</td>
        </tr>
        <tr>
          <th>Kondensor</th>
          <td>SWEP Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Vatttenflödesvolym</th>
          <td>
            1,1m
            <sup>3</sup>/h
          </td>
        </tr>
        <tr>
          <th>Högsta Kondenseringstemperatur</th>
          <td>55°C</td>
        </tr>
        <tr>
          <th>Anslutningsgänga</th>
          <td>G25</td>
        </tr>
        <tr>
          <th>Anslutningsrör (Vatten)</th>
          <td>Minst 22mm koppar / 25mm PEX</td>
        </tr>
        <tr>
          <th>Cirkulationspump</th>
          <td>Inbyggd 6/25 180mm A-klass</td>
        </tr>
        <tr>
          <th>Köldmedium</th>
          <td>R32</td>
        </tr>
        <tr>
          <th>Köldmediummängd</th>
          <td>1150g</td>
        </tr>
        <tr>
          <th>Vattenfilter</th>
          <td>Extern filterball med magnet (Medföljer)</td>
        </tr>
        <tr>
          <th>Vikt</th>
          <td>104kg</td>
        </tr>
        <tr>
          <th>Mått (LxBxH)</th>
          <td>1105x425x695mm</td>
        </tr>
        <tr>
          <th>Garanti</th>
          <td>2 år</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'IRLT920TekniskSpec'
}
</script>
